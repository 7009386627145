<template>
  <div class="blog-wrapper">
    <!-- blogs -->
    <b-row class="blog-list-wrapper">
      <b-col
        cols="12"
        v-html="postConfig.headerHtml"
      >
      </b-col>

      <!-- <b-col cols="12">
        <b-form-group class="blog-search">
          <b-input-group class="input-group-merge">
            <b-form-input
              id="search-input"
              v-model="search_query"
              placeholder="검색어를 입력해 주세요."
            />
            <b-input-group-append
              class="cursor-pointer"
              is-text
            >
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col> -->
      <b-col
        cols="12"
        class="mb-2"
        v-if="!blogList.length"
      >
        <div class="demo-spacing-0">
          <b-alert
            variant="warning"
            show
          >
            <div class="alert-body">
              <span>등록된 내용이 없습니다.</span>
            </div>
          </b-alert>
        </div>
      </b-col>
      <b-col
        v-for="blog in blogList"
        :key="blog.id"
        md="6"
      >
        <b-card
          tag="article"
          no-body
        >
          <!-- <b-link :to="{ name: 'pages-blog-detail', params: { id: blog.id } }">
            <b-img
              :src="blog.img"
              :alt="blog.img.slice(5)"
              class="card-img-top"
            />
          </b-link> -->
          <b-card-body>
            <b-card-title>
              <b-link
                :to="{
                  name: 'post-detail',
                  params: { postid: blog.id },
                }"
                class="blog-title-truncate text-body-heading"
              >
                {{ blog.title }}
              </b-link>
            </b-card-title>

            <b-media no-body>
              <!--
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
                <b-avatar
                  href="javascript:void(0)"
                  size="42"
                  :src="
                    blog.User.profileImage
                      ? `/uploads/profileimage/3/${blog.User.profileImage}`
                      : require('@/assets/images/avatars/avatar.jpeg')
                  "
                />
              </b-media-aside>
              -->
              <b-media-body>
                <!-- <small class="text-muted mr-50">by</small> -->

                <!-- 2022-12-16 작성자 숨김 -->
                <!--
                <small>
                  <b-link class="text-body">{{ blog.User.userName }}</b-link>
                  <br />
                  <b-link class="text-body text-right">{{
                    convertGenName(blog.User.GenType.genName)
                  }}</b-link>
                </small>
                -->
                <!-- <span class="text-muted ml-75 mr-50">|</span> -->
                <small class="text-muted float-right">{{
                  blog.createdAt.substring(0, 16)
                }}</small>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        v-if="postConfig.writable || userData.role === 'manager'"
      >
        <div class="float-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :to="{
              name: 'post-create',
              params: { menuid: $route.params.id },
            }"
          >
            글쓰기
          </b-button>
        </div>
      </b-col>
      <b-col
        cols="12"
        v-if="blogList.length"
      >
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
            v-model="page"
            align="center"
            :total-rows="rows"
            use-router
            :per-page="limit"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            @page-click="pageClick"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
      <b-col
        cols="12"
        v-html="postConfig.footerHtml"
      >
      </b-col>
    </b-row>
  </div>
  <!--/ blogs -->
</template>

<script>
  import {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BImg,
    BCardBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BAlert,
  } from 'bootstrap-vue';
  import { kFormatter } from '@core/utils/filter';
  import Ripple from 'vue-ripple-directive';

  export default {
    components: {
      BButton,
      BRow,
      BCol,
      BCard,
      BFormInput,
      BCardText,
      BCardBody,
      BCardTitle,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
      BLink,
      BBadge,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      BImg,
      BPagination,
      BAlert,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: JSON.parse(localStorage.getItem('userData')),
        search_query: '',
        blogList: [],
        postConfig: {},
        page: 1,
        limit: 10,
        rows: 0,
      };
    },
    created() {
      this.getContent(1);
    },
    methods: {
      kFormatter,
      async pageClick(button, page) {
        this.page = page;
        const query = Object.assign({}, this.$route.query);
        query.page = page;
        await this.$router.push({ query });
        this.getContent(page);
      },
      async getContent(page) {
        const id = this.$route.params.id; // menuid
        this.$http.get(`/post/list/${id}?page=${page}`).then(res => {
          // console.dir(res.data);
          this.blogList = res.data.list;
          this.rows = res.data.count;
          this.postConfig = res.data.config;

          document.querySelector('.content-header-title').textContent =
            res.data.config.menuName;
        });
      },
      convertGenName(genName) {
        return typeof genName === 'number' ? `${genName}기` : genName;
      },
    },
    watch: {
      $route: function () {
        this.getContent();
      },
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-blog.scss';
</style>
